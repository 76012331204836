import React, { ReactElement } from "react"
import * as styles from "./PageHero.module.scss"

export type PageHeroProps = {
  content?: ReactElement
  media?: ReactElement
  overlay?: boolean | null // TODO for dato-source typegen, verify
}

const PageHero = (props: PageHeroProps) => {
  //console.log("Rendering PageHero", props)

  return (
    <header className={styles.header}>
      <div className={`${styles.container}`}>
        <div className={`${styles.mediaArea}`}>{props.media}</div>
        {props.overlay && <div className={styles.overlay} />}
        <div className={`layout-container ${styles.contentArea}`}>
          <div className={`${styles.content}`}>{props.content}</div>
        </div>
      </div>
    </header>
  )
}

export default PageHero
