import { graphql } from "gatsby"
import React from "react"
// import useArticlePath from "../../hooks/useArticlePath"
// import useRoutePath from "../../hooks/useRoutePath"
import DatoContentModules from "../dato/DatoContentModules"
import BackgroundMedia from "../molecules/BackgroundMedia"
import PageHero from "../molecules/PageHero"
//import * as styles from "./Page.module.scss"

const Page = (props: Queries.DatoPageFragment) => {
  //console.log("Rendering Page", props)
  // const getArticlePath = useArticlePath()
  // const getRoutePath = useRoutePath()
  // const routePath = getRoutePath(props.introLinkRoute?.originalId)
  // const articlePath = !routePath && props.introLinkTargetArticle && getArticlePath(props.introLinkTargetArticle.originalId)

  return (
    <>
      <PageHero
        overlay={props.gradientOverlay}
        media={<BackgroundMedia {...props.mainMedia} />}
        content={
          <>
            <h1>{props.title}</h1>
            {props.introText && <p>{props.introText}</p>}

            {/* {routePath && (
              <Link to={routePath}>
                <Button label={props.introLinkText} tabIndex={-1} />
              </Link>
            )} */}

            {/* {articlePath && (
              <Link to={articlePath}>
                <Button label={props.introLinkText} tabIndex={-1} />
              </Link>
            )} */}
          </>
        }
      />

      <DatoContentModules modules={props.modules} />
    </>
  )
}

export default Page

export const query = graphql`
  fragment DatoPage on DatoCmsPage {
    mainMedia {
      ...DatoMedia
    }
    title
    introText
    #introLinkText
    #introLinkRoute {
    #  originalId
    #}
    #introLinkTargetArticle {
    #  originalId
    #}
    gradientOverlay
    ...DatoPageModules
  }
`
